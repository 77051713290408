import { authService } from '../authService/index';
import { BaseService } from '../baseService/index';

export class OrderApiV4 extends BaseService {
  constructor({ authService }) {
    super();
    this.authService = authService;
  }

  createOrder = (data) => {
    data = {
      ...data,
      options: { source: 'dispatcher-form', ...data.options },
    };
    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/orders/create'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  senderCreateOrder = (data) => {
    data = {
      ...data,
      options: { source: 'booking-form' },
    };
    return this.authService
      .post(this.getUmbrellaApiUrlV4('sender/orders/create'), data)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  editOrder = (orderNumber, data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/order/${orderNumber}`), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  reapplyOperationsForOrders = (orderIds) => {
    return this.authService
      .post(this.getUmbrellaApiUrlV4(`company/operations/reset_and_reapply_operations`), { order_ids: orderIds })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  asyncReapplyOperationsForOrders = (orderIds) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4(`company/operations/async_reset_and_reapply_operations`), { order_ids: orderIds })
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getReapplyOperationsForOrdersStatus = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/operations/async_reset_and_reapply_operations/${jobId}/status`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkEditOrders = (data) => {
    return this.authService
      .put(this.getUmbrellaApiUrlV4('company/orders/bulk_edit'), data)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBulkEditOrdersStatus = (jobId) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/orders/bulk_edit/${jobId}/status`))
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getOrderDetail = (orderNumber, orderExternalId) => {
    const requestPayload = {
      number: orderNumber,
      external_id: orderExternalId,
    };

    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/order`), requestPayload)
      .then((response) => {
        const result = this.extractData(response);
        return result && result['data'] ? result['data'] : null;
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getBatchUploadSampleUrl({ format, slug, templateId, userType = 'dispatcher' }) {
    return this.getUmbrellaApiUrlV4(
      `public/orders/download_sample?company_slug=${slug}&format=${format}&template_id=${templateId}&user_type=${userType}`
    );
  }

  searchOrders = (payload) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/orders`), payload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getTransportOrders = (payload) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4(`company/transport_orders`), payload)
      .then((response) => {
        return this.extractData(response);
      })
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkAssignOrders = (workerId, orderNumbers) => {
    const params = {
      order_numbers: orderNumbers,
      worker_id: workerId,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_assign'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkTransferOrders = (partner_company_slug, orderNumbers) => {
    const params = {
      order_numbers: orderNumbers,
      partner_company_slug: partner_company_slug,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_transfer'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkPlanTransfers = (partner_company_slug, orderNumbers) => {
    const params = {
      order_numbers: orderNumbers,
      partner_company_slug: partner_company_slug,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_plan_transfer'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkWithdrawOrders = (orderNumbers) => {
    const params = {
      order_numbers: orderNumbers,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_withdraw'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkWithdrawBroadcastedOrders = (broadcastIds) => {
    const params = {
      broadcast_ids: broadcastIds,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/bulk_withdraw_broadcasts'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkWithdrawPlannedTransfers = (orderNumbers) => {
    const params = {
      order_numbers: orderNumbers,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/planned_transfers/cancel'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkCompleteOrders = (
    orderNumbers,
    PODPreSignedUrl,
    completedAt,
    workerId,
    assignToAllTasks,
    allSelectedOrdersTransferred
  ) => {
    let params;

    if (allSelectedOrdersTransferred) {
      params = PODPreSignedUrl
        ? {
            order_numbers: orderNumbers,
            pod_url: PODPreSignedUrl,
            completion_time: completedAt,
          }
        : {
            order_numbers: orderNumbers,
            completion_time: completedAt,
          };
    } else {
      params = PODPreSignedUrl
        ? {
            order_numbers: orderNumbers,
            pod_url: PODPreSignedUrl,
            completion_time: completedAt,
            worker_id: workerId,
            assign_to_all_tasks: assignToAllTasks,
          }
        : {
            order_numbers: orderNumbers,
            completion_time: completedAt,
            worker_id: workerId,
            assign_to_all_tasks: assignToAllTasks,
          };
    }

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_complete'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkCancelOrders = (orderNumbers, cancelledNotes, reasonCode, forceCancellation) => {
    const params = {
      order_numbers: orderNumbers,
      forceCancellation: forceCancellation,
      cancelled_notes: cancelledNotes,
      reason_code: reasonCode,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/bulk_cancel'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkActionStatus = (link) => {
    return this.authService
      .get(link)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  broadcastOrders = ({ orderNumbers, partnershipIds, expiresAt, message }) => {
    const params = {
      order_numbers: orderNumbers,
      partnership_ids: partnershipIds,
      expires_at: expiresAt,
      message: message,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/transfer_broadcasts'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  confirmTransfer = ({ orderNumbers }) => {
    const params = { order_numbers: orderNumbers };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/order/planned_transfers/confirm'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  getTransferBroadcasts = (payload) => {
    return this.authService
      .get(this.getUmbrellaApiUrlV4('company/transfer_broadcasts'), payload)
      .then((response) => this.extractData(response))
      .catch((error) => this.handleNewErrorFormat(error));
  };

  bulkAcceptBroadcastedOrders = (broadcastIds) => {
    const params = {
      broadcast_ids: broadcastIds,
    };

    return this.authService
      .post(this.getUmbrellaApiUrlV4('company/transfer_broadcasts/bulk_accept'), params)
      .then((response) => this.extractData(response.data))
      .catch((error) => this.handleNewErrorFormat(error));
  };
}

export const orderApiV4 = new OrderApiV4({ authService });
